import { useEffect } from "react";
import "./style.scss";

import { Overlay } from "./Sections/Overlay";
import { SearchInput } from "./Components/SearchInput";
import { TabItem } from "./Components/TabItem";
import { SuasMedidas } from "./Components/SuasMedidas";
import { Success } from "./Success";
import { useMobile } from "./Hooks/useMobile";
import { useGlobalStore } from "./Stores/Store";
import { Header } from "./Sections/Header";
import { TabContentItem } from "./Components/TabContentItem";
import { MobileHeader } from "./Sections/MobileHeader";
import { MobileOverlay } from "./Sections/MobileOverlay";
import { GoToTop } from "./Components/GoToTop";

export function App() {
  const { isMobile } = useMobile();
  const {
    currentTab,
    selection,
    medidas,
    filter,
    showSuccess,
    setMedidas,
    setFilter,
    setTab,
    setTotalVolume,
    setTotalCount,
    setInitialCategories,
    setCategories,
    categories,
    originalCategories,
    setFiliais,
  } = useGlobalStore();

  useEffect(() => {
    function loadItems() {
      return fetch(`/api/v1/resources`).then((response) => response.json());
    }

    function loadFiliais() {
      return fetch(`/api/v1/resources/filiais`).then((response) =>
        response.json()
      );
    }

    loadItems().then((response) => {
      setInitialCategories(response);
      setTab(response[0].slug);
    });

    loadFiliais().then((response) => {
      setFiliais(response);
    });
  }, []);

  useEffect(() => {
    let count = 0;
    const volume =
      Object.values(selection).reduce((acc, currentItem) => {
        if (!currentItem) return acc;
        if (currentItem.qty !== 0) count++;
        return acc + parseInt(currentItem.qty) * parseFloat(currentItem.volume);
      }, 0) +
      Object.values(medidas).reduce(
        (acc, currentItem) => acc + currentItem.soma,
        0
      );

    setTotalVolume(Number.isNaN(volume) ? 0 : volume.toFixed(2));
    setTotalCount(count);
  }, [selection, medidas]);

  useEffect(() => {
    if (filter.length > 0) {
      const pattern = new RegExp(`${filter.trim()}`, "i");
      const filteredCategories = structuredClone(originalCategories)
        .map((category) => {
          category.items = Object.values(category.items).filter((item) => {
            return pattern.test(item.name);
          });
          return category;
        })
        .filter((category) => category.items.length > 0);

      setCategories(filteredCategories);

      if (filteredCategories.length) {
        setTab(filteredCategories[0].slug);
      }
    } else {
      setCategories(originalCategories);

      if (originalCategories.length) {
        setTab(originalCategories[0].slug);
      }
    }
  }, [filter]);

  function handleSuasMedidas({ position, values }) {
    setMedidas({ [position]: values });
  }

  if (showSuccess) {
    return <Success />;
  }

  return (
    <div className="meuselfstorage__calculadora">
      {!isMobile && <Header />}
      {isMobile && <MobileHeader />}

      <div className="meuselfstorage__calculadora__main">
        {!isMobile && (
          <div className="meuselfstorage__tabs">
            <SearchInput setFilter={setFilter} defaultValue={filter} />
            <ul>
              {categories.map((category) => (
                <TabItem
                  key={category.id}
                  slug={category.slug}
                  setTab={setTab}
                  currentTab={currentTab}
                >
                  {category.name}
                </TabItem>
              ))}

              <TabItem
                slug="suas_medidas"
                currentTab={currentTab}
                setTab={setTab}
              >
                Suas medidas
              </TabItem>
            </ul>
          </div>
        )}

        <div className="meuselfstorage__tab__content__container__wrapper">
          {categories.length === 0 && filter.trim().length > 0 ? (
            <div className="meuselfstorage__tab__content meuselfstorage__tab__content__not-found">
              Não foi encontrado nenhum item com o filtro especificado
            </div>
          ) : null}
          {categories.map(
            (category) =>
              currentTab === category.slug && (
                <div
                  className="meuselfstorage__tab__content__container"
                  key={category.id}
                >
                  {Object.values(category.items).length === 0 && (
                    <div className="meuselfstorage__tab__content meuselfstorage__tab__content__not-found">
                      Não foi encontrado itens nesta categoria
                    </div>
                  )}
                  {Object.values(category.items).length > 0 && (
                    <div className="meuselfstorage__tab__content">
                      <ul>
                        {Object.values(category.items).map(
                          (item, categoryKey) => (
                            <TabContentItem
                              key={categoryKey}
                              item={item}
                              category={category}
                            />
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )
          )}
          {currentTab === "suas_medidas" && (
            <div className="meuselfstorage__tab__content__container">
              <div className="meuselfstorage__tab__content">
                <div className="meuselfstorage__tab__content__medidas-wrapper">
                  <p>
                    Insira as medidas em centímetros nos campos abaixo. Por
                    exemplo: 110cm
                  </p>
                  <SuasMedidas
                    initialValue={medidas && medidas[0]}
                    onChange={handleSuasMedidas}
                    position={0}
                  />
                  <SuasMedidas
                    initialValue={medidas && medidas[1]}
                    onChange={handleSuasMedidas}
                    position={1}
                  />
                  <SuasMedidas
                    initialValue={medidas && medidas[2]}
                    onChange={handleSuasMedidas}
                    position={2}
                  />
                  <SuasMedidas
                    initialValue={medidas && medidas[3]}
                    onChange={handleSuasMedidas}
                    position={3}
                  />
                  <SuasMedidas
                    initialValue={medidas && medidas[4]}
                    onChange={handleSuasMedidas}
                    position={4}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isMobile && (
        <>
          <MobileOverlay />
          <GoToTop />
        </>
      )}
      {!isMobile && <Overlay />}
    </div>
  );
}
