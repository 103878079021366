import { formatVolume } from "../helpers";
import InputMask from "react-input-mask";
import { useState } from "react";
import { useGlobalStore } from "../Stores/Store";
import { useSession } from "../Hooks/useSession";

export function OverlayForm() {
  const { selection, totalVolume, medidas, filiais, setShowSuccess } =
    useGlobalStore();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const formData = [...new FormData(e.target)].reduce(
      (acc, [name, value]) => {
        return { ...acc, [name]: value };
      },
      {
        items: Object.values(selection)
          .map((item) => ({ id: item.id, qty: item.qty }))
          .filter((item) => item.qty > 0),
        calculo_total: totalVolume,
        medidas,
      }
    );

    setErrors({});
    setIsSubmitting(true);

    fetch("/api/v1/lead", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const { errors, success } = result;

        if (errors) {
          setErrors(errors);
          setIsSubmitting(false);
        } else {
          setShowSuccess(true);
          useSession().clearSession();
        }
      })
      .catch((error) => {
        console.log({ error });
        setErrors(errors);
        setIsSubmitting(false);
      });
  }

  const renderErrors = (field) =>
    errors?.[field]?.map((error, index) => (
      <div key={index} className="text-white my-2 rounded p-2 bg-danger">
        {error}
      </div>
    ));

  return (
    <div className="meuselfstorage__calculadora__overlay__container__content__form">
      <h3>Finalize sua solicitação de orçamento</h3>
      <p className="meuselfstorage__calculadora__overlay__container__content__volume">
        Você precisa de <span>{formatVolume(totalVolume)}m³</span>
      </p>

      <div>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <label htmlFor="filial">Unidade</label>
            <select
              id="filial"
              placeholder="Filial"
              required={true}
              name="filial_id"
            >
              <option selected disabled value="">
                Selecione
              </option>
              {Object.entries(filiais).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
            {renderErrors("filial_id")}
          </fieldset>

          <fieldset>
            <label htmlFor="nome_completo">Nome Completo</label>
            <input
              id="nome_completo"
              type="text"
              placeholder="Nome Sobrenome"
              required={true}
              name="nome"
            />
            {renderErrors("nome")}
          </fieldset>

          <fieldset>
            <label htmlFor="email">E-mail</label>
            <input
              id="email"
              type="email"
              placeholder="seuemail@email.com"
              required={true}
              name="email"
            />
            {renderErrors("email")}
          </fieldset>

          <fieldset>
            <label htmlFor="telefone">Telefone</label>
            <InputMask mask="(99) 99999-9999">
              <input
                id="telefone"
                type="text"
                placeholder="00 9000-0000"
                required={true}
                name="telefone"
              />
            </InputMask>
            {renderErrors("telefone")}
          </fieldset>

          <div className="meuselfstorage__calculadora__overlay__container__content__form__2-col">
            <fieldset>
              <label htmlFor="a_partir">A partir de quando</label>
              <input
                id="a_partir"
                type="date"
                placeholder="A partir de quando"
                name="a_partir"
              />
              {renderErrors("a_partir")}
            </fieldset>

            <fieldset>
              <label htmlFor="quanto_tempo">Quanto tempo:</label>
              <select
                name="quanto_tempo"
                id="quanto_tempo"
                required={true}
                defaultValue=""
              >
                <option disabled={true} value={""}>
                  Selecione
                </option>
                <option value="1 mês">1 mês</option>
                <option value="3 meses">3 meses</option>
                <option value="6 meses">6 meses</option>
                <option value="1 ano">1 ano</option>
                <option value="outro">Outro</option>
              </select>
              {renderErrors("quanto_tempo")}
            </fieldset>
          </div>

          <fieldset>
            <label htmlFor="motivo">Motivo</label>
            <select name="motivo" id="motivo" defaultValue="" required>
              <option disabled={true}>Selecione</option>
              <option value="documentos">Documentos</option>
              <option value="estoque">Estoque</option>
              <option value="falta_espaco">Falta de Espaço</option>
              <option value="mudanca">Mudança</option>
              <option value="reforma_construcao">Reforma/Construção</option>
            </select>
            {renderErrors("motivo")}
          </fieldset>

          <fieldset>
            <label htmlFor="mensagem">Mensagem</label>
            <textarea
              name="mensagem"
              id="mensagem"
              rows="3"
              placeholder="Detalhes adicionais"
            />
            {renderErrors("mensagem")}
          </fieldset>

          <button disabled={isSubmitting}>Enviar</button>
        </form>
      </div>
    </div>
  );
}
