import { useEffect, useRef } from "react";
import { useGlobalStore } from "../Stores/Store";

export function ItemInput({ item }) {
  const { mutateSelection, setSelection, selection } = useGlobalStore();
  const ref = useRef();

  useEffect(() => {
    if (selection[item.id]) {
      ref.current.value = selection[item.id].qty;
    }
  }, [selection]);

  function decrement() {
    mutateSelection(item, true, (qty) => {
      ref.current.value = qty;
    });
  }

  function increment() {
    mutateSelection(item, false, (qty) => {
      ref.current.value = qty;
    });
  }

  function handleChange(e) {
    setSelection(item.id, { ...item, qty: parseInt(e.target.value) });
  }

  function handleKeyUp(e) {
    if (e.keyCode > 47 && e.keyCode < 58) {
      //   setSelection(item.id, { ...item, qty: parseInt(e.target.value) });
    }

    // return false;
  }

  return (
    <div className="meuselfstorage__tab__content__item__handle">
      <button onClick={decrement}>-</button>
      <input
        ref={ref}
        type="number"
        min="0"
        defaultValue={
          selection && selection[item.id] ? selection[item.id].qty : 0
        }
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      <button onClick={increment}>+</button>
    </div>
  );
}
