export function SuccessImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 569 528"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "optimizeQuality",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    >
      <path
        fill="#fefefe"
        d="M-.5-.5h569v528h-169a45.408 45.408 0 0 1 8-8h20v-19c35.161-36.381 62.661-78.048 82.5-125 27.529-69.311 41.862-141.311 43-216a487.57 487.57 0 0 0-1-61c-2.04-5.376-5.873-8.543-11.5-9.5-64.375 4.925-124.708-8.408-181-40a730.55 730.55 0 0 1-53-34c-12.178-7.48-24.178-7.148-36 1-41.875 31.934-88.542 54.268-140 67-27.732 6.033-55.732 7.867-84 5.5-3.986.61-7.986 1.11-12 1.5-4.073 1.072-6.907 3.572-8.5 7.5-7.286 96.331 7.047 189.331 43 279 19.46 46.64 46.627 87.973 81.5 124v19h20l9 8H-.5V-.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#f4f4f4"
        d="M427.5 500.5v-139c.167-17.67 0-35.336-.5-53a102.95 102.95 0 0 0-14.5-8c0-.667-.333-1-1-1v-80h-25c3.896-48.854-15.77-84.354-59-106.5-45.233-16.483-84.067-6.983-116.5 28.5-17.427 23.027-24.593 49.027-21.5 78h-23a2934.703 2934.703 0 0 0-1 80c-.667 0-1 .333-1 1a280.528 280.528 0 0 0-13.5 8c-.5 17.664-.667 35.33-.5 53v139c-34.873-36.027-62.04-77.36-81.5-124-35.953-89.669-50.286-182.669-43-279 1.593-3.928 4.427-6.428 8.5-7.5 4.014-.39 8.014-.89 12-1.5 28.268 2.367 56.268.533 84-5.5 51.458-12.732 98.125-35.066 140-67 11.822-8.148 23.822-8.48 36-1a730.55 730.55 0 0 0 53 34c56.292 31.592 116.625 44.925 181 40 5.627.957 9.46 4.124 11.5 9.5a487.57 487.57 0 0 1 1 61c-1.138 74.689-15.471 146.689-43 216-19.839 46.952-47.339 88.619-82.5 125Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#fefefe"
        d="M411.5 299.5c.167 23.336 0 46.669-.5 70A4156.607 4156.607 0 0 0 291.5 436c-1.667.667-3.333.667-5 0a4189.768 4189.768 0 0 0-120-66.5c.331-23.506-.002-46.839-1-70-.03-26.672.303-53.339 1-80h23c-3.093-28.973 4.073-54.973 21.5-78 32.433-35.483 71.267-44.983 116.5-28.5 43.23 22.146 62.896 57.646 59 106.5h25v80Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#f5f5f5"
        d="M276.5 130.5c41.393-2.904 68.893 15.096 82.5 54a151.218 151.218 0 0 1 2.5 35h-147c-3.379-46.884 17.288-76.55 62-89Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#323e43"
        d="M171.5 198.5h-2c.667-30.667 1.333-30.667 2 0Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#666e72"
        d="M405.5 173.5c2.411 9.823 2.578 19.823.5 30a90.721 90.721 0 0 1-.5-30Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#465156"
        d="M129.5 189.5c.06-.543.393-.876 1-1a263.467 263.467 0 0 1 23 20.5c-2.476-.747-4.809-2.081-7-4a376.593 376.593 0 0 1-17-15.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#39444a"
        d="M442.5 190.5a2.428 2.428 0 0 1 2 .5c-5.584 6.615-11.918 11.948-19 16a393.399 393.399 0 0 1 17-16.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#7e8588"
        d="M169.5 198.5h2c-.667 5.333-1.333 5.333-2 0Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#596267"
        d="M114.5 223.5a200.65 200.65 0 0 1 33 1.5c-12 .667-24 .667-36 0 1.237-.232 2.237-.732 3-1.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#545d62"
        d="M432.5 223.5c9.4-.386 18.734.114 28 1.5a256.122 256.122 0 0 1-32 0c1.558-.21 2.891-.71 4-1.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#39444a"
        d="M150.5 240.5c1.025.397 1.192 1.063.5 2L133.5 258c-.667.667-1.333.667-2 0a197.891 197.891 0 0 1 19-17.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#384248"
        d="M424.5 241.5c.397-1.025 1.063-1.192 2-.5a216.136 216.136 0 0 1 18 17c-.667.667-1.333.667-2 0a424.237 424.237 0 0 1-18-16.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#ff8805"
        d="M337.5 254.5c7.639 4.488 15.306 8.988 23 13.5A7644.69 7644.69 0 0 0 277 385.5a14.972 14.972 0 0 1-3.5 3 3696.012 3696.012 0 0 0-54-48.5c-1.017-1.049-1.184-2.216-.5-3.5a589.878 589.878 0 0 1 17.5-15 1017.34 1017.34 0 0 1 32 28.5c.667.667 1.333.667 2 0a9113.21 9113.21 0 0 0 67-95.5Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#e06618"
        d="M164.5 300.5a1156.835 1156.835 0 0 1 1 68l-4-1a130.692 130.692 0 0 0-11-6c-.167-17.67 0-35.336.5-53a280.528 280.528 0 0 1 13.5-8Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#ea9b68"
        d="M164.5 300.5c0-.667.333-1 1-1a1225.063 1225.063 0 0 1 1 70c-.667 0-1-.333-1-1 .331-22.839-.003-45.506-1-68Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#e06618"
        d="M412.5 300.5a102.95 102.95 0 0 1 14.5 8c.5 17.664.667 35.33.5 53a58.37 58.37 0 0 0-9 5 16.076 16.076 0 0 0-5 2c-1-22.657-1.333-45.324-1-68Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#e27f1d"
        d="M150.5 361.5a130.692 130.692 0 0 1 11 6 3151.827 3151.827 0 0 0 92 56 830.733 830.733 0 0 0-98 62.5l1 .5a894.06 894.06 0 0 0 99.5-62c10.984 6.326 21.984 12.66 33 19a1645.462 1645.462 0 0 1 33.5-19c31.68 23.333 65.013 43.833 100 61.5a859.6 859.6 0 0 0-98-62.5 3283.527 3283.527 0 0 0 94-57 58.37 58.37 0 0 1 9-5v158h-277v-158Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#ba6b1f"
        d="M411.5 299.5c.667 0 1 .333 1 1-.333 22.676 0 45.343 1 68a16.076 16.076 0 0 1 5-2 3283.527 3283.527 0 0 1-94 57 859.6 859.6 0 0 1 98 62.5c-34.987-17.667-68.32-38.167-100-61.5a1645.462 1645.462 0 0 0-33.5 19c-11.016-6.34-22.016-12.674-33-19a894.06 894.06 0 0 1-99.5 62l-1-.5a830.733 830.733 0 0 1 98-62.5 3151.827 3151.827 0 0 1-92-56l4 1c0 .667.333 1 1 1a4189.768 4189.768 0 0 1 120 66.5c1.667.667 3.333.667 5 0A4156.607 4156.607 0 0 1 411 369.5c.5-23.331.667-46.664.5-70Z"
        style={{
          opacity: 1,
        }}
      />
      <path
        fill="#f5f5f5"
        d="M170.5 519.5h237a45.408 45.408 0 0 0-8 8h-220l-9-8Z"
        style={{
          opacity: 1,
        }}
      />
    </svg>
  );
}
